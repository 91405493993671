
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { Brand, BrandIndexItem, BrandQueryResultItem } from '@/api/braendz';

import VerticalBrandTile from './VerticalBrandTile.vue';

@Component({
    components: {
        VerticalBrandTile
    }
})
export default class VerticalBrandTileGrid extends Vue {
    public isBrandQueryResultItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandQueryResultItem => {
        return (b as BrandQueryResultItem)?.indexItem !== undefined;
    }
    public isBrandIndexItem = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is BrandIndexItem => {
        return (b as BrandIndexItem)?.brandId !== undefined;
    }
    public isBrand = (b: BrandQueryResultItem | BrandIndexItem | Brand): b is Brand => {
        return !this.isBrandQueryResultItem(b) && !this.isBrandIndexItem(b);
    }

    // Component Properties:
    @Prop({ required: true })
    public brands!: (BrandQueryResultItem | BrandIndexItem | Brand)[];

    @Prop({ required: false, default: true, type: Boolean })
    public showCommon!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showDates!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showOwner!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showNiceClasses!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public showGoodsServices!: boolean;

    @Prop({ required: false, default: true, type: Boolean })
    public showViennaClasses!: boolean;

    // Methods:
    public getIdOrIndex(item: BrandQueryResultItem | BrandIndexItem | Brand | null | undefined, index: number) {
        if(item) {
            if(this.isBrandQueryResultItem(item)) return item.indexItem?.id ?? item.indexItem?.brandId ?? index;
            if(this.isBrandIndexItem(item)) return item.id ?? item.brandId ?? index;
            if(this.isBrand(item)) return item.id ?? index;
        }
        return index;
    }
}
