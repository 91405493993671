var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto d-flex flex-column",
      attrs: { light: "", elevation: "3" },
    },
    [
      _vm.brandStateCategoryColor
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.brandStateCategoryColor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-system-bar",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  color: _vm.brandStateCategoryColor,
                                  height: "6",
                                },
                              },
                              "v-system-bar",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3019154328
              ),
            },
            [
              _vm.brandStateCategoryIcon
                ? _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.brandStateCategoryIcon)),
                  ])
                : _vm._e(),
              _vm.brandStateCategory
                ? [
                    _vm.brandStateCategory.shortDescription
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.brandStateCategory.shortDescription)
                          ),
                        ])
                      : _vm.brandStateCategory.key
                      ? _c("span", [_vm._v(_vm._s(_vm.brandStateCategory.key))])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "HiddenBrandOverlay",
        { attrs: { enabled: !_vm.loading && _vm.hidden } },
        [
          _vm.loading
            ? [
                _c("v-skeleton-loader", {
                  attrs: {
                    type: "list-item-avatar-two-line, divider, list-item-two-line@4",
                  },
                }),
              ]
            : [
                _c(
                  "v-toolbar",
                  {
                    attrs: {
                      flat: "",
                      light: "",
                      rounded: "lg",
                      color: "transparent",
                    },
                  },
                  [
                    _vm.brandOrDummy.registrationOfficeCode &&
                    _vm.brandOrDummy.registrationOfficeCode.key &&
                    _vm.brandOrDummy.registrationOfficeCode.description
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _vm.brandOrDummy.registrationOfficeCode &&
                                      _vm.brandOrDummy.registrationOfficeCode
                                        .key
                                        ? _c(
                                            "v-img",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    src: _vm.$braendz.registrationOfficeCodeFlag(
                                                      _vm.brandOrDummy
                                                        .registrationOfficeCode
                                                        .key
                                                    ),
                                                    contain: "",
                                                    "max-height": "40",
                                                    "max-width": "40",
                                                  },
                                                },
                                                "v-img",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              386382874
                            ),
                          },
                          [
                            _vm.brandOrDummy.registrationOfficeCode
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.brandOrDummy.registrationOfficeCode
                                        .description
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm.brandOrDummy.registrationOfficeCode &&
                        _vm.brandOrDummy.registrationOfficeCode.key
                      ? _c("v-img", {
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag(
                              _vm.brandOrDummy.registrationOfficeCode.key
                            ),
                            contain: "",
                            "max-height": "40",
                            "max-width": "40",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "v-toolbar-title",
                      { staticClass: "ml-3" },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { "two-line": "" } },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                !_vm.brandOrDummy.brandType ||
                                _vm.brandOrDummy.brandType.key !== "Figurative"
                                  ? _c(
                                      "TruncateTooltip",
                                      {
                                        attrs: {
                                          label: _vm.brandOrDummy.name,
                                          top: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-h5 text-truncate",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.brandOrDummy.name) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-item-title",
                                      [_c("v-icon", [_vm._v("$no-text")])],
                                      1
                                    ),
                                _vm.brandOrDummy.brandType &&
                                _vm.brandOrDummy.brandType.description
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.brandOrDummy.brandType
                                              .description
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm.brandOrDummy.brandType
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.brandOrDummy.brandType.key
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("v-list-item-subtitle", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "brand.tile.valueNotAvailable"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _vm.brand
                      ? _c("BrandDetailsPopup", {
                          attrs: { id: _vm.brandId },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            "fa-light fa-memo-circle-info"
                                          ),
                                        ]),
                                        _vm._v(" Details "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3016226215
                          ),
                        })
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "fa-light fa-ellipsis-stroke-vertical"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          [
                            !_vm.brandBookmarkContainerId
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: { click: _vm.bookmarkBrand },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-solid fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Bookmark this brand"),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteBookmarkConfirmationPopupVisible = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Remove bookmark"),
                                    ]),
                                  ],
                                  1
                                ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { disabled: !_vm.toggleExclusion },
                                on: { click: _vm.toggleExclusion },
                              },
                              [
                                _vm.isExcluded
                                  ? _c(
                                      "v-list-item-icon",
                                      { attrs: { color: "primary" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-eye"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-item-icon",
                                      { attrs: { color: "text-light" } },
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-eye-slash"),
                                        ]),
                                      ],
                                      1
                                    ),
                                _vm.isExcluded
                                  ? _c("v-list-item-title", [
                                      _vm._v("Include this brand"),
                                    ])
                                  : _c("v-list-item-title", [
                                      _vm._v("Exclude this brand"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mx-3" }),
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex flex-row" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  tile: "",
                                  color: "transparent",
                                  "min-width": "500",
                                  "max-width": "500",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm.brandOrDummy.brandState &&
                                          _vm.brandOrDummy.brandState
                                            .description
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.brandOrDummy
                                                        .brandState.description
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm.brandOrDummy.brandState
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.brandOrDummy
                                                        .brandState.key
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1 text--disabled",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.valueNotAvailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("brand.tile.state")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm.brandOrDummy.registrationNumber
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block text-truncate body-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.brandOrDummy
                                                          .registrationNumber
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.registrationNumber"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm.brandOrDummy.applicationNumber
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block text-truncate body-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.brandOrDummy
                                                          .applicationNumber
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.applicationNumber"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block text-truncate body-1 text--disabled",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.valueNotAvailable"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.registrationNumber"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm.brandOrDummy.applicationDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$d(
                                                        new Date(
                                                          _vm.brandOrDummy.applicationDate
                                                        ),
                                                        "short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1 text--disabled",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.valueNotAvailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.applicationDate"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm.brandOrDummy.registrationDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$d(
                                                        new Date(
                                                          _vm.brandOrDummy.registrationDate
                                                        ),
                                                        "short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1 text--disabled",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.valueNotAvailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.registrationDate"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm.brandOrDummy.expiryDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$d(
                                                        new Date(
                                                          _vm.brandOrDummy.expiryDate
                                                        ),
                                                        "short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1 text--disabled",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.valueNotAvailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.expiryDate"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm.brandOrDummy
                                            .oppositionPeriodEndDate
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$d(
                                                        new Date(
                                                          _vm.brandOrDummy.oppositionPeriodEndDate
                                                        ),
                                                        "short"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block text-truncate body-1 text--disabled",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.valueNotAvailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.oppositionPeriodEndDate"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        !_vm.userAccount
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: { plain: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$auth.signIn()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-right-to-bracket"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "brand.tile.loginMessage"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.ownerAddress"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm.brandOrDummy.ownerAddress
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "body-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$braendz.getAddressLine(
                                                          _vm.brandOrDummy
                                                            .ownerAddress
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.ownerAddress"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "body-1 text--disabled",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.valueNotAvailable"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.ownerAddress"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "mr-auto",
                                attrs: {
                                  flat: "",
                                  tile: "",
                                  color: "transparent",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _vm.brandOrDummy.niceClasses &&
                                        _vm.brandOrDummy.niceClasses.length > 0
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.niceClasses"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.brandOrDummy.niceClasses,
                                                  function (niceClass, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "d-inline",
                                                      },
                                                      [
                                                        niceClass.description
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-chip",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1 mb-1",
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-chip",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "body-1",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        niceClass.key
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      niceClass.description
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "mr-1 mb-1",
                                                                attrs: {
                                                                  small: "",
                                                                  outlined: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        niceClass.key
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.niceClasses"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block text-truncate body-1 text--disabled",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.valueNotAvailable"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _vm.brandOrDummy.viennaClasses &&
                                        _vm.brandOrDummy.viennaClasses.length >
                                          0
                                          ? _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.viennaClasses"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.brandOrDummy
                                                    .viennaClasses,
                                                  function (
                                                    viennaClass,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass: "d-inline",
                                                      },
                                                      [
                                                        viennaClass.description
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-chip",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1 mb-1",
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                        outlined:
                                                                                          "",
                                                                                      },
                                                                                  },
                                                                                  "v-chip",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "body-1",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        viennaClass.key
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      viennaClass.description
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "mr-1 mb-1",
                                                                attrs: {
                                                                  small: "",
                                                                  outlined: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        viennaClass.key
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption d-block",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.viennaClasses"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "d-block text-truncate body-1 text--disabled",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "brand.tile.valueNotAvailable"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "align-self-center",
                                attrs: {
                                  flat: "",
                                  tile: "",
                                  color: "transparent",
                                },
                              },
                              [
                                _vm.fullBrandLogoUrl
                                  ? _c("v-img", {
                                      staticClass: "ma-3",
                                      attrs: {
                                        "max-width": "200",
                                        src: _vm.fullBrandLogoUrl,
                                        contain: "",
                                      },
                                    })
                                  : _c(
                                      "v-icon",
                                      {
                                        staticClass: "ma-3",
                                        attrs: { size: "80" },
                                      },
                                      [_vm._v("fa-light fa-image-slash")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.brandId
                  ? _c("SaveBrandBookmarkPopup", {
                      attrs: { brandId: _vm.brandId },
                      model: {
                        value: _vm.saveBookmarkPopupVisible,
                        callback: function ($$v) {
                          _vm.saveBookmarkPopupVisible = $$v
                        },
                        expression: "saveBookmarkPopupVisible",
                      },
                    })
                  : _vm._e(),
                _c("ConfirmationPopup", {
                  attrs: {
                    yes: "",
                    cancel: "",
                    question: "",
                    title: "Entfernen",
                    text: "Möchten Sie diese Marke aus dieser Liste entfernen?",
                  },
                  on: { yes: _vm.removeBookmark },
                  model: {
                    value: _vm.deleteBookmarkConfirmationPopupVisible,
                    callback: function ($$v) {
                      _vm.deleteBookmarkConfirmationPopupVisible = $$v
                    },
                    expression: "deleteBookmarkConfirmationPopupVisible",
                  },
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }