var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        _vm._l(_vm.brands, function (brand, index) {
          return _c(
            "v-col",
            {
              key: _vm.getIdOrIndex(brand, index),
              attrs: { xl: "3", lg: "4", md: "6", xs: "12" },
            },
            [
              _c("VerticalBrandTile", {
                staticClass: "fill-height",
                attrs: {
                  brand: brand,
                  "max-height": _vm.showGoodsServices ? 1000 : undefined,
                  showCommon: _vm.showCommon,
                  showDates: _vm.showDates,
                  showOwner: _vm.showOwner,
                  showNiceClasses: _vm.showNiceClasses,
                  showGoodsServices: _vm.showGoodsServices,
                  showViennaClasses: _vm.showViennaClasses,
                  canAddToBookmarks: true,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }