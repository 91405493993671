var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            _vm._l(_vm.includedBrands, function (brand, index) {
              return _c(
                "v-col",
                { key: _vm.getIdOrIndex(brand, index), attrs: { cols: "12" } },
                [
                  _vm.isBrandQueryResultItem(brand)
                    ? _c("HorizontalBrandTile", {
                        attrs: { brand: brand.indexItem },
                      })
                    : _c("HorizontalBrandTile", { attrs: { brand: brand } }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.excludedBrands.length > 0
        ? _c(
            "v-expansion-panels",
            { staticClass: "py-10", attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", { staticClass: "pl-3" }, [
                    _vm._v(" Excluded Brands "),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "pl-3" },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.excludedBrands, function (brand, index) {
                          return _c(
                            "v-col",
                            {
                              key: _vm.getIdOrIndex(brand, index),
                              attrs: { xl: "3", lg: "4", md: "6", xs: "12" },
                            },
                            [
                              _vm.isBrandQueryResultItem(brand)
                                ? _c("HorizontalBrandTile", {
                                    attrs: { brand: brand.indexItem },
                                  })
                                : _c("HorizontalBrandTile", {
                                    attrs: { brand: brand },
                                  }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }